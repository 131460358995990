import * as React from 'react';

import { LiveChatWidget } from '@livechat/widget-react';

interface OwnProps {
	state?: 'hidden' | 'minimized' | 'maximized';
	onStateChange?: (state: 'hidden' | 'minimized' | 'maximized') => void;
}

export const Chat: React.FC<OwnProps> = (props: OwnProps) => {
	return (
		<LiveChatWidget
			license={process.env.app.chatId}
			visibility={props.state ?? 'minimized'}
			onVisibilityChanged={(data) => props.onStateChange?.(data.visibility)}
		/>
	);
};
