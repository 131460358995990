import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { IPixelContext } from '@app/objects/PixelContext';
import { RoutePaths } from '@app/utilities/RoutePathVars';

const keepZipLocations: Array<string> = [
	RoutePaths.requestQuotePage,
	RoutePaths.contactPage,
];

export function usePixel(): IPixelContext {
	const location = useLocation();
	// Keep track of zip code entered by user
	const [zip, setZip] = React.useState<string>('');

	React.useEffect(() => {
		if (!zip) return;

		if (!keepZipLocations.includes(location.pathname)) {
			setZip('');
		}
	}, [location.pathname, zip]);

	return React.useMemo(() => ({
		zip,
		setZip,
	}), [zip]);
}
