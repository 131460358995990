/* eslint-disable camelcase */
import React from 'react';

import { PixelContext } from '@app/services/contexts';

const base: string = 'https://px.adentifi.com/Pixels';
const ids: Record<string, number> = {
	general: 21782,
	phone: 22427,
};

type PixelActionTracking = keyof typeof ids & 'phone';

interface PixelTrackingProps {
	'a_id': number;
	'cv_2'?: string;
}

type PixelQuery = 'a_id' | 'cv_1' | 'cv_2' | 'cv_3' | 'p_url' | 'uq';

function getUrl(props: PixelTrackingProps): string {
	const uq = Math.random() * 10000000000000;
	const href = encodeURIComponent(window.location.href);

	const query: Partial<Record<PixelQuery, unknown>> = {
		a_id: props.a_id,
		uq,
		p_url: href,
	};

	if (props.cv_2) {
		query.cv_2 = props.cv_2;
	}

	const queryString: string = Object.entries(query)
		.map(([key, value]) => `${key}=${value}`)
		.join('&');

	return `${base}?${queryString}`;
}

export const PixelTracking: React.FC = () => {
	const { zip } = React.useContext(PixelContext);
	const url = React.useMemo(() => getUrl({
		a_id: ids.general,
		cv_2: zip,
	}), [window.location.href, zip]);

	return (
		<img
			src={url}
			alt="pixel tracking"
			height={1}
			width={1}
			style={{ display: 'none' }}
		/>
	);
};

interface PixelActionTrackerProps {
	action?: PixelActionTracking;
}

export const PixelActionTracking: React.FC<PixelActionTrackerProps> = (props: PixelActionTrackerProps) => {
	const url = React.useMemo(() => getUrl({
		a_id: ids.phone,
	}), [props.action]);

	if (!props.action) return null;

	return (
		<img
			src={url}
			alt="pixel action tracking"
			height={1}
			width={1}
			style={{ display: 'none' }}
		/>
	);
};
