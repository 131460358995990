import React from 'react';
import { useSelector } from 'react-redux';

import { Icon } from '@app/image/icon';
import { RootStore } from '@app/store';
import { Chat } from '@app/components/Chat/Chat';
import { CallButton } from '@app/components/Button/CallButton';

import '@app/scss/components/contactsWidget.scss';

function useQuery(query: string): boolean {
	const [complies, setComplies] = React.useState(false);

	React.useEffect(() => {
		const media = window.matchMedia(query);
		const handler = () => {
			setComplies(media.matches);
		};

		handler();
		media.addEventListener('change', handler);

		return () => media.removeEventListener('change', handler);
	}, [query]);

	return complies;
}

export const ContactsWidget: React.FC = () => {
	const [state, setState] = React.useState<'hidden' | 'maximized'>('hidden');
	const phone = useSelector((state: RootStore) => state.header.item?.phone);
	const matches = useQuery('(max-width: 768px)');

	if (matches) {
		return (
			<section className="contacts-widget">
				<div className="contacts-widget__container">
					<CallButton phone={phone ?? ''}>
						<div className="contacts-widget__item phone">
							<div className="contacts-widget__icon-wrapper"><Icon.Phone fill="white" /></div>
							Phone
						</div>
					</CallButton>
					<a href="mailto:customerservice@onkinevacationcenter.com?subject=Website Assistance">
						<div className="contacts-widget__item email">
							<div className="contacts-widget__icon-wrapper"><Icon.Email fill="white" /></div>
							Email
						</div>
					</a>
					<div
						className="contacts-widget__item chat"
						onClick={() => setState('maximized')}
					>
						<Chat
							state={state}
							onStateChange={(state) => state !== 'maximized' && setState('hidden')}
						/>
						<div className="contacts-widget__icon-wrapper"><Icon.Chat /></div>
						Chat
					</div>
				</div>
			</section>
		);
	}

	return <Chat />;
};
