export interface IPixelContext {
	zip: string;
	setZip: (value: string) => void;
}

export const defaultPixelContextState = {
	zip: '',
	setZip: () => {
		throw new Error('Access to PixelContext should be only for descendants of PixelProvider');
	},
};
