import * as React from 'react';

import '@app/scss/components/button.scss';
import { PixelActionTracking } from '@app/components/PixelTracking/PixelTracking';

interface CallButtonProps {
	phone: string;
}

type OwnProps = React.PropsWithChildren<CallButtonProps>;

export const CallButton: React.FC<OwnProps> = (props: OwnProps) => {
	const [action, setAction] = React.useState<'phone'>();

	return (
		<>
			<PixelActionTracking action={action} />
			<a
				href={`tel:${props.phone}`}
				className="btn"
				onClick={() => setAction('phone')}
			>
				{props.children}
			</a>
		</>
	);
};
