import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import moment from 'moment';
import * as Yup from 'yup';
import {
	Field,
	FieldProps,
	useFormikContext,
} from 'formik';

import { TextField } from '@app/components/TextField/TextField';
import { FormTemplate, IMessage, SuccessMessage } from '@app/pages/Forms/FormTemplate';
import { TextAreaField } from '@app/components/TextField/TextAreaField';
import { Select } from '@app/components/Selects/Select';
import { Option } from '@app/components/Selects/Option';
import { Nullable } from '@app/objects/Utility';
import { simpleChoiceOption } from '@app/components/Selects/LocalSelectOptions';
import { LocalSelectOptions } from '@app/objects/LocalSelectOptions';

import { phoneValidator, simpleNumberSchema, simpleStringSchema } from '@app/services/validationSchemas';
import { useData } from '@app/hooks/data/useData';
import { PhoneField } from '@app/components/PhoneField/PhoneField';
import { request } from '@app/services/api';
import { NotificationType } from '@app/components/Notification/Notification';
import { ManagersSelect } from '@app/components/Selects/SmartSelects/ManagersSelect';
import { DestinationsSelect } from '@app/components/Selects/SmartSelects/DestinationsSelect';
import { ShipsSelect } from '@app/components/Selects/SmartSelects/ShipsSelect';
import { SuppliersSelect } from '@app/components/Selects/SmartSelects/SuppliersSelect';

import { useSelector } from '@app/hooks/useSelector';
import { formatPhone } from '@app/services/phone';
import { RootStore } from '@app/store';
import { PixelContext } from '@app/services/contexts';
import { CallButton } from '@app/components/Button/CallButton';

const subtitle = `
Fill out the Cruise Quote Request form below and we'll find exactly the cruise you're looking for,
at the lowest rate available. You will be contacted by an experienced travel agent.
`;

export interface RequestQuoteFormValues {
	firstName: string;
	lastName: string;
	email: string;
	zip: string;
	phone: string;
	manager: Nullable<number>;
	numberOfTravelers: Nullable<number>;

	cruiseLine: Nullable<number>;
	ship: Nullable<number>;
	destination: Array<string>;
	date: Array<string>;
	signUp: boolean;
	note: string;

	agreeSubmittingTerms: boolean;
}

const initialValues: RequestQuoteFormValues = {
	firstName: '',
	lastName: '',
	email: '',
	zip: '',
	phone: '',
	manager: -2,
	numberOfTravelers: null,

	cruiseLine: null,
	date: [],
	destination: [],
	ship: null,
	signUp: true,
	note: '',

	agreeSubmittingTerms: false,
};

const validationSchema = Yup.object().shape({
	firstName: simpleStringSchema,
	lastName: simpleStringSchema,
	zip: simpleStringSchema.min(5).max(6),
	email: Yup.string().email('Invalid email format').required('This field is required'),
	phone: phoneValidator,
	date: Yup.array().min(1, 'Select a date'),
	destination: Yup.array().min(1, 'Select a destination'),
	numberOfTravelers: simpleNumberSchema,
	note: simpleStringSchema,
	agreeSubmittingTerms: Yup.bool().oneOf([true], 'Field must be checked'),
});

interface Option<T> {
	id: T;
	value: string;
}

const dateFormat: string = 'MMMM YYYY';
const dates: Array<string> = new Array(24)
	.fill(moment().utc().set('date', 15))
	.map((value: moment.Moment, index: number) => moment(value).add(index, 'month').format(dateFormat));

const travelers: Array<number> = new Array(11).fill(1).map((value: number, index: number) => value + index);

interface ServerFormData {
	additionalNotes?: Nullable<string>;
	cruise?: Nullable<boolean>;
	date?: Nullable<number>;
	shipId?: Nullable<number>;
	cruiseLineId?: Nullable<number>;
	destination?: Nullable<string>;
}

function getUpdate(data: ServerFormData): Partial<RequestQuoteFormValues> {
	const res: Partial<RequestQuoteFormValues> = {};

	if (data.additionalNotes) {
		res.note = data.additionalNotes;
	}

	if (data.cruiseLineId) {
		res.cruiseLine = data.cruiseLineId;
	}

	if (data.shipId) {
		res.ship = data.shipId;
	}

	if (data.date) {
		res.date = [moment(data.date).utc().format(dateFormat)];
	}

	if (data.destination) {
		res.destination = [data.destination];
	}

	return res;
}

function getQuery(params: URLSearchParams): string {
	const map = new Map<string, Nullable<string>>();

	map.set('specialsId', params.get('specials'));
	map.set('bookingId', params.get('bkId'));
	map.set('encldId', params.get('encldid'));

	const list: Array<string> = [];
	map.forEach((value: Nullable<string>, key: string) => {
		if (value) {
			list.push(`${key}=${value}`);
		}
	});

	const query = list.join('&');
	if (query.length) return `?${query}`;

	return '';
}

const InnerForm: React.FC = () => {
	const [params] = useSearchParams();
	const preFill = useData<ServerFormData>(`api/bridge/v1/request-form/${getQuery(params)}`);
	const form = useFormikContext<RequestQuoteFormValues>();

	React.useEffect(() => {
		if (!preFill.item || preFill.loading) return;

		const changes = getUpdate(preFill.item);
		form.setValues({ ...form.values, ...changes }, false);
	}, [preFill.item, preFill.loading]);

	return (
		<div className="col-12">
			<div className="form-group_large form-group_outlined">
				<h3 className="form-group__title">Personal Information</h3>
				<div className="layout-container">
					<Field name="firstName">
						{({ field, form }: FieldProps<string, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6 col-xl-4">
								<TextField
									value={field.value}
									onChange={(value: string) => form.setFieldValue(field.name, value, false)}
									onBlur={field.onBlur}

									title="First Name*"

									text={form.touched.firstName ? form.errors.firstName : undefined}
									error={Boolean(form.errors.firstName && form.touched.firstName)}
								/>
							</div>
						)}
					</Field>
					<Field name="lastName">
						{({ field, form }: FieldProps<string, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6 col-xl-4">
								<TextField
									value={field.value}
									onChange={(value: string) => form.setFieldValue(field.name, value, false)}
									onBlur={field.onBlur}

									title="Last Name*"

									text={form.touched.lastName ? form.errors.lastName : undefined}
									error={Boolean(form.errors.lastName && form.touched.lastName)}
								/>
							</div>
						)}
					</Field>
					<Field name="email">
						{({ field, form }: FieldProps<string, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6 col-xl-4">
								<TextField
									value={field.value}
									onChange={(value: string) => form.setFieldValue(field.name, value, false)}
									onBlur={field.onBlur}

									title="E-mail*"
									inputProps={{ type: 'email' }}

									text={form.touched.email ? form.errors.email : undefined}
									error={Boolean(form.errors.email && form.touched.email)}
								/>
							</div>
						)}
					</Field>
					<Field name="zip">
						{({ field, form }: FieldProps<string, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6 col-xl-4">
								<TextField
									value={field.value}
									onChange={(value: string) => form.setFieldValue(field.name, value, false)}
									onBlur={field.onBlur}

									title="Zip/Postal Code*"

									text={form.touched.zip ? form.errors.zip : undefined}
									error={Boolean(form.errors.zip && form.touched.zip)}
								/>
							</div>
						)}
					</Field>
					<Field name="phone">
						{({ field, form }: FieldProps<string, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6 col-xl-4">
								<PhoneField
									value={field.value}
									onChange={(value: string) => form.setFieldValue(field.name, value, false)}
									onBlur={field.onBlur}

									title="Phone*"

									text={form.touched.phone ? form.errors.phone : undefined}
									error={Boolean(form.errors.phone && form.touched.phone)}
								/>
							</div>
						)}
					</Field>
					<Field name="manager">
						{({ field, form }: FieldProps<number, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6 col-xl-4">
								<ManagersSelect
									value={field.value}
									onChange={(value?: Nullable<number>) => form.setFieldValue(field.name, value, false)}
								/>
							</div>
						)}
					</Field>
				</div>
			</div>
			<div className="form-group_large form-group_outlined">
				<h3 className="form-group__title">Vacation Details</h3>
				<div className="layout-container">
					<Field name="date">
						{({ field, form }: FieldProps<Array<string>, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6">
								<Select<string>
									placeholder="Desired Month of Travel*"
									value={field.value}
									onChange={(value: Array<string>) => form.setFieldValue(field.name, value)}
									multiple
									text={form.touched.date ? form.errors.date as string : undefined}
									error={Boolean(form.errors.date && form.touched.date)}
								>
									{
										dates.map((item: string) => (
											<Option key={item} value={item}>
												{item}
											</Option>
										))
									}
								</Select>
							</div>
						)}
					</Field>
					<Field name="destination">
						{({ field, form }: FieldProps<Array<string>, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6">
								<DestinationsSelect
									value={field.value}
									onChange={(value: Array<string>) => form.setFieldValue(field.name, value, false)}
									error={form.errors.destination as string}
								/>
							</div>
						)}
					</Field>
					<Field name="cruiseLine">
						{({ field, form }: FieldProps<number, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6">
								<SuppliersSelect
									value={field.value}
									onChange={(value?: Nullable<number>) => form.setFieldValue(field.name, value, false)}
								/>
							</div>
						)}
					</Field>
					<Field name="ship">
						{({ field, form }: FieldProps<number, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6">
								<ShipsSelect
									value={field.value}
									onChange={(value?: Nullable<number>) => form.setFieldValue(field.name, value, false)}
									cruiseLine={form.values.cruiseLine}
								/>
							</div>
						)}
					</Field>
					<Field name="numberOfTravelers">
						{({ field, form }: FieldProps<number, RequestQuoteFormValues>) => (
							<div className="col-12 col-sm-6">
								<Select
									placeholder="Number of Travelers*"
									value={field.value}
									onChange={(value: number) => form.setFieldValue(field.name, value)}
									allowClear={false}

									text={form.touched.numberOfTravelers ? form.errors.numberOfTravelers as string : undefined}
									error={Boolean(form.errors.numberOfTravelers && form.touched.numberOfTravelers)}
								>
									{
										travelers.map((option: number) => (
											<Option key={option} value={option}>
												{option > 10 ? '10+' : option.toString()}
											</Option>
										))
									}
								</Select>
							</div>
						)}
					</Field>
					<Field name="signUp">
						{({ field, form }: FieldProps) => (
							<div className="col-12 col-sm-6">
								<Select
									placeholder="Would you like to sign up for our email newsletter?"
									value={field.value ? Number(field.value) : field.value}
									onChange={(value: number) => form.setFieldValue(field.name, value)}
								>
									{
										simpleChoiceOption.map((option: LocalSelectOptions) => (
											<Option key={option.value} value={option.value}>
												{option.text}
											</Option>
										))
									}
								</Select>
							</div>
						)}
					</Field>
				</div>
			</div>
			<div className="layout-container">
				<Field name="note">
					{({ field, form }: FieldProps<number, RequestQuoteFormValues>) => (
						<div className="col-12">
							<TextAreaField
								value={field.value}
								onChange={(value: string) => form.setFieldValue(field.name, value, false)}
								onBlur={field.onBlur}

								title="Additional Comments* (Click here)"
								text={form.touched.note ? form.errors.note : undefined}
								error={Boolean(form.errors.note && form.touched.note)}
							/>
						</div>
					)}
				</Field>
			</div>
		</div>
	);
};

const SuccessExtraContent: React.FC = () => {
	const header = useSelector((store: RootStore) => store.header.item);
	const phone = header?.phone;

	return (
		<p className="form__subtitle" style={{ marginTop: 25, width: '80%' }}>
			One of our travel specialists will contact you shortly to review your custom travel options.
			If you have any questions and would like immediate help, please call us
			{
				phone ? (
					<span>
						&nbsp;at <CallButton phone={phone}>{formatPhone(phone)}</CallButton>
					</span>
				) : null
			}
		</p>
	);
};

export const RequestQuote = () => {
	const { setZip } = React.useContext(PixelContext);
	const [message, setMessage] = React.useState<IMessage | undefined>(undefined);

	return (
		<FormTemplate
			title="Request a Quote"
			subtitle={subtitle}
			innerForm={<InnerForm />}
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: RequestQuoteFormValues, { setSubmitting, resetForm }) => {
				const params = new URLSearchParams(window.location.search);

				const bookingId = params.get('bkId');
				const fuseAction = params.get('fuseaction');
				const specialsId = params.get('specials');

				setMessage(undefined);
				request('api/bridge/v1/request-form', {
					method: 'POST',
					data: {
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						zip: values.zip,
						phone: values.phone,
						pvmId: values.manager,
						date: values.date,
						destination: values.destination,
						cruiseLineId: values.cruiseLine,
						shipId: values.ship,
						travelersCount: values.numberOfTravelers,
						additionalNotes: values.note,
						signUp: Boolean(values.signUp),
						specialsId,
						bookingId,
						fuseAction,
					},
				})
					.then((response) => {
						console.log(response);
						setZip(values.zip);

						resetForm();
						setMessage({
							type: NotificationType.Success,
							content: (
								<SuccessMessage
									title="Quote Request Received"
									subtitle="Thank You for the Inquiry"
									extra={<SuccessExtraContent />}
								/>
							),
						});
					})
					.catch((error: Error) => {
						console.warn('Failed to request a quote: ', error.message);
						setMessage({ type: NotificationType.Error, content: 'Failed to request a quote' });
					})
					.finally(() => setSubmitting(false));
			}}
			message={message}
		/>
	);
};
